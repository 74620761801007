<template>
    <BModal
        id="addProcessModal"
        ref="addProcessModal"
        size="lg"
        modal-class="bar bar-primary"
        header-class="align-items-center"
        no-close-on-backdrop
        static
        visible
        @hide="hide"
        @shown="$refs.titleInput.focus()"
    >
        <template slot="modal-header">
            <h4 class="modal-title">
                Add a Process
            </h4>

            <FontAwesomeIcon
                icon="times"
                class="ml-auto modal-close"
                size="2x"
                @click="close"
            />
        </template>

        <div
            id="tooltipContainer"
            class="is-invalid"
        />

        <ValidationObserver
            ref="validationObserver"
            tag="div"
            class="container-fluid"
        >
            <div class="row">
                <div class="col-12">
                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="title"
                        rules="required"
                        class="mt-3"
                    >
                        <BFormGroup :state="!errors.length">
                            <template slot="label">
                                Title

                                <FontAwesomeIcon
                                    id="titlePopover"
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    data-location="Process Add Modal"
                                />

                                <BPopover
                                    custom-class="kc-help-wording"
                                    target="titlePopover"
                                    title="Title"
                                    triggers="hover click"
                                    data-cy="titlePopover"
                                >
                                    {{ helpWording.title }}
                                </BPopover>
                            </template>

                            <BInput
                                ref="titleInput"
                                v-model="title"
                                type="text"
                                data-cy="title"
                                placeholder="enter a title for the process..."
                            />

                            <ValidationError
                                :errors="errors"
                                target="titlePopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="code"
                        rules="required"
                        class="mt-3"
                    >
                        <BFormGroup :state="!errors.length">
                            <template slot="label">
                                Code

                                <FontAwesomeIcon
                                    id="codePopover"
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    data-location="Process Add Modal"
                                />

                                <BPopover
                                    custom-class="kc-help-wording"
                                    target="codePopover"
                                    title="Code"
                                    triggers="hover click"
                                    data-cy="codePopover"
                                >
                                    {{ helpWording.code }}
                                </BPopover>
                            </template>

                            <BInputGroup>
                                <BInput
                                    ref="codeInput"
                                    v-model="code"
                                    type="text"
                                    data-cy="code"
                                    placeholder="enter a code for the process..."
                                    @keypress="codeOverride = true"
                                />

                                <BInputGroupAppend>
                                    <BButton
                                        variant="secondary"
                                        @click="resetCode"
                                    >
                                        Reset
                                    </BButton>
                                </BInputGroupAppend>
                            </BInputGroup>

                            <ValidationError
                                :errors="errors"
                                target="codePopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="policySystem"
                        rules="required"
                        class="mt-3"
                    >
                        <BFormGroup :state="!errors.length">
                            <template slot="label">
                                Policy System

                                <FontAwesomeIcon
                                    id="policySystemPopover"
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    data-location="Process Add Modal"
                                />

                                <BPopover
                                    custom-class="kc-help-wording"
                                    target="policySystemPopover"
                                    title="Policy System"
                                    triggers="hover click"
                                    data-cy="policySystemPopover"
                                >
                                    {{ helpWording.policy_system }}
                                </BPopover>
                            </template>

                            <KCDropdown
                                id="policySystem"
                                v-model="policySystem"
                                :options="policySystemOptions"
                                data-cy="policySystemSelect"
                                placeholder="choose a policy system..."
                            />

                            <ValidationError
                                :errors="errors"
                                target="policySystemPopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{errors}"
                        tag="div"
                        name="useTemplate"
                        rules="required"
                        class="mt-3"
                    >
                        <BFormGroup :state="!errors.length">
                            <template slot="label">
                                Start with a template?

                                <FontAwesomeIcon
                                    id="useTemplatePopover"
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    data-location="Process Add Modal"
                                />

                                <BPopover
                                    custom-class="kc-help-wording"
                                    target="useTemplatePopover"
                                    title="Template"
                                    triggers="hover click"
                                    data-cy="useTemplatePopover"
                                >
                                    {{ helpWording.use_template }}
                                </BPopover>
                            </template>

                            <BFormRadioGroup
                                id="useTemplate"
                                v-model="useTemplate"
                                data-cy="useTemplate"
                                :options="booleanOptions"
                                name="useTemplate"
                                @change="handleUseTemplateChange"
                            />

                            <ValidationError
                                :errors="errors"
                                target="useTemplatePopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <ValidationProvider
                        v-if="useTemplate"
                        v-slot="{errors}"
                        tag="div"
                        name="template"
                        rules="required"
                        class="mt-3"
                    >
                        <BFormGroup :state="!errors.length">
                            <template slot="label">
                                Select template

                                <FontAwesomeIcon
                                    id="templatePopover"
                                    icon="info-circle"
                                    class="fa kc-help-wording"
                                    data-location="Process Add Modal"
                                />

                                <BPopover
                                    custom-class="kc-help-wording"
                                    target="templatePopover"
                                    title="Template"
                                    triggers="hover click"
                                    data-cy="templatePopover"
                                >
                                    {{ helpWording.template }}
                                </BPopover>
                            </template>

                            <KCDropdown
                                id="template"
                                ref="templateInput"
                                v-model="template"
                                data-cy="template"
                                :options="templateOptions"
                            />

                            <ValidationError
                                :errors="errors"
                                target="templatePopover"
                                tool-tip-container="tooltipContainer"
                                triggers=""
                            />
                        </BFormGroup>
                    </ValidationProvider>

                    <RelatedItemSelect
                        class="mt-3"
                        @selection="taskId = $event.taskId"
                    />
                </div>
            </div>
        </ValidationObserver>

        <template slot="modal-footer">
            <button
                id="addProcessButton"
                class="btn btn-primary"
                data-cy="addProcess"
                :disabled="saving"
                @click="save"
            >
                <template
                    v-if="saving"
                >
                    <FontAwesomeIcon
                        icon="spinner"
                        pulse
                    />
                    Saving Process...
                </template>
                <template
                    v-else
                >
                    Begin Building Process
                    <FontAwesomeIcon
                        icon="angle-right"
                        class="ml-2 mr-0"
                    />
                </template>
            </button>

            <button
                class="btn btn-secondary"
                @click="close"
            >
                Cancel
            </button>
        </template>
    </BModal>
</template>

<script>
    import {KCDropdown} from '@imt/vue-kit-car';
    import ValidationError from '@imt/vue-toolbox/src/components/ValidationError.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import RelatedItemSelect from '@imt/vue-versioning/src/components/RelatedItemSelect.vue';
    import {snakeCase} from 'lodash';
    import {
        mapActions,
        mapGetters,
    } from 'vuex';

    export default {
        name: 'BHAddModal',
        components: {
            KCDropdown,
            RelatedItemSelect,
            ValidationError,
        },
        mixins: [
            toastsMixin,
        ],
        data() {
            return {
                booleanOptions: [
                    {text: 'Yes', value: true},
                    {text: 'No', value: false}
                ],
                templateOptions: [],
                templates: {},
                title: '',
                code: '',
                useTemplate: null,
                taskId: null,
                template: [],
                policySystem: [],
                codeOverride: false,
                saving: false,
                helpWording: {
                    title: 'Give this process a title to be easily identified.',
                    code: 'Give this process a code to be referenced by external applications.',
                    policy_system: 'Choose which Policy System this process is for - or choose All to allow for running for any Policy System.',
                    use_template: 'Select "Yes" if this process should be created from a template with a predefined set of tasks.',
                    template: 'Choose which template to use when creating this process.',
                },
            };
        },
        computed: {
            policySystemOptions() {
                return [
                    {
                        value: '',
                        text: 'All',
                    },
                    ...this.formattedPolicySystems?.map((policySystem => {
                        return {
                            value: policySystem.id,
                            text: policySystem.title,
                        };
                    }))
                ];
            },
            ...mapGetters('toolbox', [
                'formattedPolicySystems',
            ]),
        },
        watch: {
            title(newValue) {
                if (!this.codeOverride) {
                    this.code = snakeCase(newValue);
                }
            },
            useTemplate(template) {
                if (!template) {
                    this.template = null;
                }
            }
        },
        mounted() {
            this.setTemplates();
        },
        methods: {
            handleUseTemplateChange(value) {
                if (!value) {
                    this.template = [];
                }
            },
            async setTemplates() {
                const templates = await this.fetchProcessTemplates();
                for (const index in templates) {
                    const templateLabel = templates[index].title;
                    this.templateOptions.push({
                        text: templateLabel[0].toUpperCase() + templateLabel.substring(1),
                        value: templateLabel
                    });

                    this.templates[templateLabel] = templateLabel;
                }
            },
            helpWordingHover(title, content) {
                return {
                    title,
                    content: this.helpWording[content],
                    template: `<div class='' role='tooltip'><div class='arrow'></div><h3 class='popover-header'></h3><div class='popover-body'></div></div>`
                };
            },
            async save() {
                this.saving = true;
                let isValid = await this.$refs.validationObserver.validate();

                if (!isValid) {
                    this.saving = false;

                    return false;
                }

                try {
                    const response = await this.createProcess({
                        title: this.title,
                        code: this.code,
                        policySystemId: this.policySystem.length && this.policySystem[0].value ? this.policySystem[0].value : null,
                        template: this.template.length ? this.template[0].value : null,
                        taskId: this.taskId,
                    });

                    this.success('Process successfully created!');
                    this.close();

                    this.$router.push({name: 'admin.process-builder', params: {id: response.versions[0].id}});
                } catch (e) {
                    this.error('Process failed to be created!');
                    this.saving = false;
                }
            },
            close() {
                this.$bvModal.hide('addProcessModal');
            },
            async hide() {
                this.resetData();
                await this.$router.push({name: 'admin.processes.list'});
            },
            resetData() {
                this.taskId = null;
                this.title = '';
                this.policySystem = [];
                this.template = [];
                this.saving = false;
                this.codeOverride = false;
                this.$refs.validationObserver.reset();
            },
            resetCode() {
                this.codeOverride = false;
                this.code = snakeCase(this.title);
            },
            ...mapActions([
                'createProcess',
                'fetchProcessTemplates',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .modal-close
        opacity: 0.5
        transition: opacity 250ms

        &:hover
            opacity: 1.0
            cursor: pointer

        .modal
            .tooltip
                z-index: 1080

            .popover
                z-index: 1081
</style>
